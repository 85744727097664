import gql from 'graphql-tag';

export const getOutageGroups = gql`
  query getOutageGroups {
    getOutageGroups {
      id
      name
      enabled
      CompanyCodes {
        id
        outageGroupId
        companyCode
      }
    }
  }
`;
