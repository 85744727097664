import gql from 'graphql-tag';

export const getOpenOutages = gql`
  query getOpenOutages {
    getOpenOutages {
      id
      facilityId
      Facility {
        id
        gps
        facilityName
      }
      flocNumber
      notifNum
      notifDate
      notifCreatedDate
      notifChangedDate
      notifType
      shortDesc
      longDesc
      workCenter
      companyCode
      funcLoc
      funcLocDesc
      funcLocObjType
      funcLocSort
      codingCatType
      codingCodeGrp
      codingCode
      reqStart
      reqEnd
      version
      Tasks {
        id
        sapOutageId
        taskNumber
        sortNumber
        taskCode
        codeText
        taskText
        longText
        statusCode
        statusText
      }
    }
  }
`;
