import gql from 'graphql-tag';

export const getSapOutage = gql`
  query getSapOutage($outageId: String) {
    getSapOutage(outageId: $outageId) {
      id
      facilityId
      Facility {
        id
        facilityName
        facilityUid
        flocNumber
        Aor {
          id
          name
        }
      }
      flocNumber
      notifNum
      notifDate
      notifCreatedDate
      notifChangedDate
      notifType
      shortDesc
      longDesc
      workCenter
      companyCode
      funcLoc
      funcLocDesc
      funcLocObjType
      funcLocSort
      codingCatType
      codingCodeGrp
      codingCode
      reqStart
      reqEnd
      version
      Tasks {
        id
        sapOutageId
        taskNumber
        sortNumber
        taskCode
        codeText
        taskText
        longText
        statusCode
        statusText
      }
      Causes {
        id
        sapOutageId
        itemNum
        issueText
        causeNum
        causeCatalogType
        causeCodeGroup
        causeCode
        causeCodeText
        causeText
      }
    }
  }
`;
